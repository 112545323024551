import React, { useState, useEffect } from "react";
import "./App.css";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

const App = () => {
  const [page, setPage] = useState(1);
  const [images, setImages] = useState([]);
  const MEMES_URL = "https://api.kanna.in/memes/?page=";

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(`${MEMES_URL}1`);
      setImages(result.data);
    };
    fetchData();
  }, []);

  const fetchMoreData = async () => {
    const result = await axios(`${MEMES_URL}${page+1}`);
    setImages(images.concat(result.data));
    setPage(page+1)
  };

  return (
    <div className="App">
      <div style={{ maxWidth: "800px", margin: "auto" }}>
        <InfiniteScroll
          dataLength={images.length}
          next={fetchMoreData}
          hasMore={page < 20}
          loader={<h4>Loading...</h4>}
        >
          <div className="grid">
          {images.map((i, index) => (
            <div className="grid-item" key={index}>
              <img src={i.url} class="img-thumbnail" alt="meme" />
            </div>
          ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default App;
